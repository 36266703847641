body   {
	background: #F4F6F7;
	font-family: 'Space Grotesk', sans-serif;
}

img.preview {
	width: 100%!important;
}

.blockbtn {
	display: block;
	padding-bottom: 10px!important;
	padding-left: 0!important;
}

.colors-t {
	padding-bottom: 0px!important;
	margin-bottom: 0px;
}

 .inputup {
    background: #d1d3e21c;
    padding: 20px;
    border: 1px solid #d1d3e2;
    border-radius: 10px;
} 
.logo-login img {
	width: 250px;
	margin: 3rem auto;
	display: block;
	
}

.card.collapse > .card-body {
	display: none!important;
}

#login {
	overflow: hidden;
}

.cover-login {
	height: 95vh;
	padding: 1rem;
	background-size: cover;
	background-image: url(../img/login-cover.png);
	margin: 1rem;
	border-radius: 2rem;
}


.emptystate-center {
	width: 40%;
	margin: auto;
	margin-top: 15rem;
	text-align: center;
}

.emptystate-onboarding {
	height: 90vh;
	background-image: url(../img/bg-emptystate.png);

}

.stepper {
	width: 50%;
	margin: auto;
	margin-bottom: 35px;
}

.step {
	width: 24%;
	display: inline-block;
	text-align: center;
}

.number {
	font-size: 32px;
	height: 55PX;
	width: 55PX;
	padding-top: 4px;
	background: #7B8999;
	color: #FFF;
	margin: auto;
	border-radius: 1rem;
	margin-bottom: 10px;
}

.step.active .number {
	background: #2491EB;

}

.step.active .title {
	color: #2491EB;
}

.main-account {
	background: #FFFFFF;
	padding: 36px;
}

.title-sec {
	margin-bottom: 35px;
}

.help {
    color: #2491EB;
    text-align: right;
    position: absolute;
    right: 20px;
}

input.portal {
    position: absolute;
    right: 20px;
    bottom: 20px;
    height: 25px;
    width: 25px;
    border-radius: 60rem;
}

.buttons-onboard {
	margin-bottom: 35px;
	margin-top: 35px;
}

.btn.back {
	border: 1px solid #415465;
}

.palette {
	margin-top: 35px;
}

.palette .card {
    border-radius: 1.5rem;
}

.palette .card-body {
    padding: .5rem;
}

.texts-customizar {
	margin-top: 35px;
}

.modulo {
	margin-bottom: 75px!important;
}

.plan-form input, .plan-form textarea {margin-bottom: 15px;}

.add-plan a {
	padding: 1.2rem;
	background: #F4F6F7; 
	font-size: 30px;
	border: 1px solid #F4F6F7!important;
	color: #415465;
}

.guardar {
	margin-top: 35px;
}


.preferences .list-card {
	border: none;
}

.list-card li {
	color:  #00A57B;
}

.check-list {
  margin: 0;
  padding-left: 1.2rem;
}

.check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 1.2rem;
  margin-bottom: 0.5rem;
}

.check-list li:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    width: 5px;
    height: 11px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #00A57B;
    transform-origin: bottom left;
    transform: rotate(45deg);
}
